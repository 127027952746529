import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  background: #eee;
`

export const Content = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  text-align: center;
`

export const Title = styled.h1`
  font-family: "Open Sans";
  color: black;
  font-weight: 400;
  margin: 1.5rem auto;
  @media (max-width: 425px) {
    font-size: 1.7rem;
  }
`

export const Header = styled.h2`
  font-family: "Open Sans";
  font-size: 2.4rem;
  color: #03989e;
  font-weight: 600;
  margin: 1.5rem auto 3rem auto;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
  @media (max-width: 500px) {
    font-size: 1.6rem;
  }
  @media (max-width: 425px) {
    font-size: 1.3rem;
  }
`

export const Text = styled.p`
  font-family: "Open Sans";
  max-width: 800px;
  color: black;
  margin: 1rem auto;
  font-size: 1.2rem;
  font-weight: 300;
  @media (max-width: 768px) {
    font-size: 1.3rem;
    text-align: left;
    padding: 0 1.5rem;
  }
`
