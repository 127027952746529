import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  background: #eee;
`

export const Content = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2.4rem;
  padding: 1rem 1.5rem;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

export const Title = styled.h1`
  font-family: "Open Sans";
  color: black;
  margin: 3rem auto;
  text-align: center;
  padding: 0 1.5rem;
  @media (max-width: 768px) {
    font-size: 1.8rem;
    line-height: 1.2;
  }
`

export const Header = styled.h3`
  font-family: "Open Sans";
  font-weight: 400;
`
export const Text = styled.p`
  font-family: "Open Sans";
  font-weight: 300;
  font-size: 1.2rem;
`

export const Button = styled.div`
  border: 1px solid transparent;
  padding: 0.6rem 1.2rem;
  background: #df992a;
  font-family: "Open Sans";
  max-width: 50%;
  border-radius: 5px;
  text-align: center;
  @media (max-width: 768px) {
    margin: 0 auto;
  }
`

export const Center = styled.div`
  @media (max-width: 768px) {
    text-align: center;
  }
`

export const Img = styled.img`
  height: 60px;
  padding: 0.6rem;
`

//Grid Containers

export const Send = styled.div`
  @media (max-width: 768px) {
    order: 2;
  }
`

export const Price = styled.div`
  @media (max-width: 768px) {
    order: 2;
  }
`

export const Service = styled.div`
  @media (max-width: 768px) {
    order: 3;
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 768px) {
    order: 7;
  }
`

export const Deliver = styled.div`
  margin-top: -8rem;
  @media (max-width: 768px) {
    margin-top: 0;
    order: 4;
  }
`

export const Payment = styled.div`
  @media (max-width: 768px) {
    order: 5;
  }
`

export const Methods = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  padding: 1.5rem;
  @media (max-width: 768px) {
    order: 6;
  }
`
