import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  background: #eee;
`

export const Content = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
  @media (max-width: 768px) {
    padding: 0;
  }
`

export const Shadow = styled.div`
  filter: drop-shadow(10px 10px 15px rgba(50, 50, 0, 0.5));
`

export const Holder = styled.div`
  width: 100%;
  background: white;
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 1rem;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    width: 80%;
    margin: 1rem auto;
  }
`

export const NumHolder = styled.div`
  display: flex;
  clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
  background: ${props => props.color};
  width: 12rem;
  height: 7rem;
`

export const StepText = styled.h4`
  font-family: "Open Sans";
  font-weight: 300;
  text-transform: uppercase;
  transform: rotate(270deg);
  width: 6rem;
  margin-top: 0.3rem;
  color: white;
  letter-spacing: 2px;
  opacity: 0.8;
`

export const StepNum = styled.h1`
  font-family: "Open Sans";
  font-weight: 300;
  font-size: 5rem;
  color: white;
  margin-top: 0.6rem;
  margin-left: -1.5rem;
`

export const TextHolder = styled.div`
  width: 80%;
  margin: 1.5rem auto;
  padding: 1.5rem;
`

export const Text = styled.p`
  font-family: "Open Sans";
  font-weight: 300;
  font-size: 1.2rem;
  margin: 0;
  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`
