import React from "react";
import { Container, Content, Header, Text, Title } from "./styles";

import { useIntl } from "gatsby-plugin-react-intl";
import i18n from "../i18n";

const Intro = () => {
  const intl = useIntl();
  return (
    <Container>
      <Content>
        <Title>{intl.formatMessage(i18n.intro.title)} </Title>
        <Header>{intl.formatMessage(i18n.intro.subtitle)}</Header>
        <Text>
          {intl.formatMessage(i18n.intro.text, {
            break: (str) => (
              <span>
                {" "}
                <br />
                <br />
                {str}
              </span>
            ),
          })}
        </Text>
      </Content>
    </Container>
  );
};

export default Intro;
