import React from "react"

import {
  Container,
  Content,
  Holder,
  NumHolder,
  Shadow,
  StepNum,
  StepText,
  Text,
  TextHolder,
} from "./styles"

import { useIntl } from "gatsby-plugin-react-intl"
import i18n from "../i18n"

const Shape = () => {
  const intl = useIntl()
  const DATA = [
    {
      Text: intl.formatMessage(i18n.process.text1),
      Color: "#FFC50B",
      Number: "1",
    },
    {
      Text: intl.formatMessage(i18n.process.text2),
      Color: "#FC672D",
      Number: "2",
    },

    {
      Text: intl.formatMessage(i18n.process.text3),
      Color: "#5B49B5",
      Number: "3",
    },

    {
      Text: intl.formatMessage(i18n.process.text4),
      Color: "#1B87C8",
      Number: "4",
    },

    {
      Text: intl.formatMessage(i18n.process.text5),
      Color: "#17A64A",
      Number: "5",
    },
  ]

  return (
    <Container>
      <Content>
        {DATA.map((item, index) => (
          <Design
            key={index}
            color={item.Color}
            number={item.Number}
            text={item.Text}
          />
        ))}
      </Content>
    </Container>
  )
}

const Design = ({ color, number, text }) => {
  return (
    <Holder>
      <Shadow>
        <NumHolder color={color}>
          <StepText>schritt</StepText>
          <StepNum>{number}</StepNum>
        </NumHolder>
      </Shadow>
      <TextHolder>
        <Text>{text}</Text>
      </TextHolder>
    </Holder>
  )
}

export default Shape
