import React from "react";
import {
  Container,
  Title,
  Content,
  Header,
  Text,
  Button,
  Send,
  Price,
  Info,
  Deliver,
  Payment,
  Center,
  Methods,
  Img,
} from "./styles";
import { useIntl } from "gatsby-plugin-react-intl";
import i18n from "../i18n";
import UPS_LOGO from "../../../../logos/UPS_logo.svg";
import PAYPAL from "../../../../logos/PayPal.svg";
import EC from "../../../../logos/EC.svg";
import VPAY from "../../../../logos/vpay.svg";
import MASTERCARD from "../../../../logos/mastercard.svg";
import VISA from "../../../../logos/visa.svg";
import MAESTERO from "../../../../logos/maestro.svg";

const DeliverPrice = () => {
  const intl = useIntl();
  return (
    <Container>
      <Title>{intl.formatMessage(i18n.allInOne.title)} </Title>
      <Content>
        <Send>
          <Header>{intl.formatMessage(i18n.allInOne.send.title)}</Header>
          <Text>
            {intl.formatMessage(i18n.allInOne.send.text, {
              break: (str) => (
                <span>
                  <br />
                  <br />
                  {str}
                </span>
              ),
            })}
          </Text>
        </Send>
        <Price>
          <Header>{intl.formatMessage(i18n.allInOne.price.title)}</Header>
          <Text>
            {intl.formatMessage(i18n.allInOne.price.text, {
              bold: (str) => <b>{str}</b>,
              break: (str) => (
                <span>
                  <br />
                  <br />
                  {str}
                </span>
              ),
            })}
          </Text>
          <Center>
            <Header>{intl.formatMessage(i18n.allInOne.service.title)}</Header>
            <Button>{intl.formatMessage(i18n.allInOne.service.text)}</Button>
          </Center>
        </Price>
        <Info>
          <div style={{ textAlign: "center" }}>
            <img src={UPS_LOGO} alt="ups" width="100px" />
            <Header>{intl.formatMessage(i18n.allInOne.info.title)} </Header>
          </div>
          <Text>{intl.formatMessage(i18n.allInOne.info.text)} </Text>
        </Info>

        <Deliver>
          <Header>{intl.formatMessage(i18n.allInOne.deliver.title)}</Header>
          <Text>
            {intl.formatMessage(i18n.allInOne.deliver.text, {
              break: (str) => (
                <span>
                  <br /> <br />
                  {str}{" "}
                </span>
              ),
            })}{" "}
          </Text>
        </Deliver>
        <Payment>
          <Header>{intl.formatMessage(i18n.allInOne.payment.title)}</Header>
          <Text>{intl.formatMessage(i18n.allInOne.payment.text)} </Text>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              flexWrap: "wrap",
            }}
          >
            <Img src={PAYPAL} alt="paypal" />
            <Img src={VPAY} alt="vpay" />
            <Img src={EC} alt="EC Karte" />
            <Img src={MASTERCARD} alt="mastercard" />
            <Img src={VISA} alt="visa" />
            <Img src={MAESTERO} alt="maestero" />
          </div>
        </Payment>
        <Methods></Methods>
      </Content>
    </Container>
  );
};

export default DeliverPrice;
