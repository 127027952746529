import React from "react";
import Page from "../components/common/page";
import { ContentRow } from "../components/common/shared-components/layouts";
import DeliverPrice from "../components/page-components/process-send-ups/all-in-one";
import Intro from "../components/page-components/process-send-ups/intro";
import Shape from "../components/page-components/process-send-ups/process";

const CONTENT_ROWS = [[<Intro />], [<Shape />], [<DeliverPrice />]];

const ProcessUps = () => {
  return (
    <Page title="board">
      {CONTENT_ROWS.map((components, key) => (
        <ContentRow key={key} components={components} />
      ))}
    </Page>
  );
};

export default ProcessUps;
