import { defineMessages } from "react-intl"

const scope = "process"

export default defineMessages({
  intro: {
    title: {
      id: `${scope}.title`,
      defaultMessage: "Abwicklung und Versand",
    },
    subtitle: {
      id: `${scope}.subtitle`,
      defaultMessage: "SCHNELL.EINFACH.OHNE RISIKO",
    },
    text: {
      id: `${scope}.text`,
      defaultMessage:
        "Der Kundenkontakt steht für uns an oberster Stelle. Daher bitten wir Sie, sich bei Fragen zur Abwicklung zunächst an uns zu wenden.  <break>Sie wünschen einen Rückruf? Dann schicken Sie uns gerne einfach eine E-Mail mit Ihrer Rufnummer – wir melden uns innerhalb von 24 Stunden bei Ihnen.</break> ",
    },
  },
  process: {
    text1: {
      id: `${scope}.text1`,
      defaultMessage:
        "Bitte füllen Sie den Reparaturauftrag nach bestem Wissen aus und senden Sie uns das defekte Gerät zu. Oder geben Sie es in unserer Werkstatt ab. Legen Sie dem Paket mit dem Gerät außerdem ein Exemplar des ausgefüllten Reparaturauftrages bei oder vermerken Sie Ihre Auftragsnummer. Diese erhalten Sie von uns beim Ausfüllen des Reparaturauftrages.",
    },
    text2: {
      id: `${scope}.text2`,
      defaultMessage:
        "Sobald das Gerät bei uns ist, führen wir eine kostenlose Fehleranalyse durch. Auf Wunsch erstellen wir einen Kostenvoranschlag für die Versicherung. Anschließend informieren wir Sie per Mail oder Telefon über das Ergebnis der Fehleranalyse.",
    },
    text3: {
      id: `${scope}.text3`,
      defaultMessage:
        "Wenn Sie uns den Auftrag bestätigen und freigeben, führen wir die Reparatur so schnell wie möglich durch. Diese dauert in der Regel zwischen drei und fünf Werktagen.",
    },
    text4: {
      id: `${scope}.text4`,
      defaultMessage:
        "Nach der Reparatur unterziehen wir das Gerät einem abschließenden Test und verpacken es sicher für den Rückversand. Sie erhalten eine Benachrichtigung über den erfolgreichen Abschluss sowie eine Rechnung mit ausgewiesener Mehrwertsteuer auf Ihren Namen.",
    },
    text5: {
      id: `${scope}.text5`,
      defaultMessage:
        "Bei Zahlungseingang schicken wir das Gerät umgehend an sie zurück. Alternativ sind Sie imstande, es in unserer Werkstatt abzuholen.",
    },
  },
  allInOne: {
    title: {
      id: `${scope}.allInOneTitle`,
      defaultMessage: "Versand, Versandkosten und Zahlungsmöglichkeiten",
    },
    send: {
      title: {
        id: `${scope}.sendTitle`,
        defaultMessage: "Versand",
      },
      text: {
        id: `${scope}.sendText`,
        defaultMessage:
          "Der Versand erfolgt grundsätzlich per UPS. Alle angebotenen Artikel (ausgenommen Reparaturen) sind sofort versandbereit.<break>Darüber hinaus ist eine kostenlose Selbstabholung auf Wunsch ebenfalls möglich.</break>",
      },
    },
    price: {
      title: {
        id: `${scope}.priceTitle`,
        defaultMessage: "Versandkosten",
      },
      text: {
        id: `${scope}.priceText`,
        defaultMessage:
          "Versand innerhalb Deutschlands per <bold>UPS - EXPRESS</bold>: ab 9,90 € (bis max. 10 KG ).<break>Versand innerhalb Europas per DPD : 19,90 €</break> <break>Selbstabholung: kostenlos</break> ",
      },
    },
    deliver: {
      title: {
        id: `${scope}.deliverTitle`,
        defaultMessage: "Lieferzeit",
      },
      text: {
        id: `${scope}.deliverText`,
        defaultMessage:
          " UPS Standard: Innerhalb Deutschlands: 2 bis 3 Werktage <break>UPS Express: Innerhalb Deutschlands: 24 Stunden </break> <break>Innerhalb Europas: 3 bis 5 Werktage</break> <break>Bitte beachten Sie, dass wir bei Verspätungen oder Lieferschwierigkeiten des Zustellers keine Haftung übernehmen können. </break>",
      },
    },
    payment: {
      title: {
        id: `${scope}.paymentTitle`,
        defaultMessage: "Zahlungsmöglichkeiten",
      },
      text: {
        id: `${scope}.paymentText`,
        defaultMessage:
          "Die Zahlung erfolgt wahlweise per Vorkasse (Banküberweisung), Paypal, oder in bar bei Abholung.",
      },
    },
    service: {
      title: {
        id: `${scope}.serviceTitle`,
        defaultMessage: "Hol- und Bringservice",
      },
      text: {
        id: `${scope}.serviceText`,
        defaultMessage: "ab 19,90 €",
      },
    },
    info: {
      title: {
        id: `${scope}.infoTitle`,
        defaultMessage: "WICHTIG",
      },
      text: {
        id: `${scope}.infoText`,
        defaultMessage:
          "Sie wünschen für den Rückversand eine höhere Transportversicherung? Dann setzen Sie sich bitte mit uns in Verbindung oder vermerken Sie Ihren Wunsch auf dem Reparaturauftrag. ",
      },
    },
  },
})
